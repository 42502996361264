import React, { useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
const Component = () => {
  const TogglePassword = (event) => {
    event = event.target;
    event.classList.toggle("icon-EyeClosed");
    event.classList.toggle("icon-EyeOpen");
    event = event.previousElementSibling;
    if (event.toggleAttribute("type")) {
      event.type = "password";
    }
  };
  const navigate = useNavigate();
  return (
    <>
      {/* if not <> -> use className='DataBox' */}
      <label className="FormData">
        <em className="RequiredData">Nombre</em>
        <div className="FormInput">
          <input placeholder="Nombre completo según identificación oficial" />
        </div>
      </label>
      <div className="Row">
        <label className="FormData">
          <em className="RequiredData">Pais</em>
          <div className="FormInput">
            <select placeholder="Email">
              <option value="mex" selected>
                México +52
              </option>
              <option value="usa">USA +1</option>
            </select>
            <i className="InputIcon icon-Select"></i>
          </div>
        </label>
        <label className="FormData">
          <em className="RequiredData">Número de móvil</em>
          <div className="FormInput">
            <input placeholder="5500000000" />
          </div>
        </label>
      </div>
      {/**
       *
       * ! InvalidInput className in label-> error input style
       *
       **/}
      <label className="FormData">
        <em className="RequiredData">Email</em>
        <div className="FormInput">
          <input placeholder="Email" />
        </div>
      </label>
      <label className="FormData">
        <em className="RequiredData">Contraseña</em>
        <div className="FormInput">
          <input placeholder="Contraseña" type="password" />
          <i className="InputIcon icon-EyeClosed" onClick={TogglePassword}></i>
        </div>
      </label>
      <div className="TextColorSec">
        Al hacer click en "Registrarse" aceptas nuestros <Link to="/404">"Términos y Condiciones"</Link>, <Link to="/404">"Términos de Uso"</Link>, <Link to="/404">"Términos de Servicio"</Link> y <Link to="/404">"Políticas de Privacidad"</Link>, así como el <Link to="/404">"Aviso de Privacidad"</Link>.
      </div>
      <div
        className="ButtonLightMedium"
        onClick={() => navigate("/login-confirmar-registro")}
      >
        REGISTRARSE
        <i className="icon-ArrowRight"></i>
      </div>
      <div>
        <p>¿Ya tienes una cuenta?</p>
        <Link to="/login">
          <b>Iniciar sesión</b>
        </Link>
      </div>
      <div className="TextColorSec">
        <em>¿Olvidaste tu contraseña? </em>
        <Link to="/login-recuperar">
          <b>Recuperar contraseña</b>
        </Link>
      </div>
    </>
  );
};

export default Component;
