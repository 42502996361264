import React from "react";
import { useNavigate, Link } from "react-router-dom";
import AgregadoItem from "../home/AgregadoItem";
const Component = () => {
  const navigate = useNavigate();
  return (
    <section className="CheckoutBox">
      <article>
        <div className="StepCheckout">
          <div className="Selected"></div>
          <div className="Selected"></div>
          <div></div>
          <div></div>
        </div>
        <div className="icon-Package"></div>
        <h5>Resumen del pedido</h5>
        <div className="ResumenPedido">
          <div>
            {<AgregadoItem noedit />}
            {<AgregadoItem noedit />}
            {<AgregadoItem noedit />}
          </div>
          <div className="ResumenCheckout">
            <div>
              <span>Subtotal</span>
              <span>$00.00</span>
            </div>
            <div>
              <span>Impuestos</span>
              <span>$00.00</span>
            </div>
            <div>
              <span>Gastos de envío</span>
              <span>$00.00</span>
            </div>
            <div>
              <h5>Total:</h5>
              <h5>$00.00</h5>
            </div>
          </div>
        </div>
        <div
          className="ButtonDarkMedium"
          onClick={() => navigate("/checkout-pago")}
        >
          CONTINUAR
          <i className="icon-Check"></i>
        </div>
      </article>
    </section>
  );
};

export default Component;
