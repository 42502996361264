import React, { useState, useRef } from 'react';
import ProductoResultado from './ProductoResultado';
import LoadingCard from './LoadingCard';

const PaginadoComponent = ({ productos, loading,coleccionPosition }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);

  const paginatedProducts = productos.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(productos.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <section className="ColeccionItems ColeccionVertical" ref={coleccionPosition}>
        {!loading ? (
          <>
            {paginatedProducts.map((producto) => (
              <ProductoResultado
                 key={producto.id || producto._id}
                  producto={producto}
                />
            ))}
          </>
        ) : (
          <LoadingCard />
        )}
      </section>

      {productos && productos.length > 12 ?
        <section className="ColeccionPagination">
          <div className="icon-CaretLeft" onClick={() => handlePageChange(currentPage - 1)} />
          <ul>
            {[...Array(totalPages)].map((_, i) => (
              <li
                key={i + 1}
                onClick={() => handlePageChange(i + 1)}
                className={currentPage === i + 1 ? 'Selected' : ''}
              >
                {i + 1}
              </li>
            ))}
          </ul>
          <div className="icon-CaretRight" onClick={() => handlePageChange(currentPage + 1)} />
        </section>
        : ''
      }
    </>
  );
};

export default PaginadoComponent;
