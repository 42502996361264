import React from 'react';
import PodriaInteresarte from '../home/PodriaInteresarte';
import Breadcrumb from '../../components/Breadcrumb';
import AgregadoItem from '../home/AgregadoItem';
const Component = () => {
	return (
		<>
			<Breadcrumb title='Mis Pedidos' />
			<section className='MisPedidos'>
				{<AgregadoItem pedido />}
				{<AgregadoItem pedido />}
				{<AgregadoItem pedido />}
			</section>
			<PodriaInteresarte />
		</>
	);
};

export default Component;