import React from "react";
import { AddToHeart } from "../../components/Funciones";
import Figure from "../../components/Figure";
import { useNavigate } from "react-router-dom";

const Component = ({ product, productUri }) => {
  const navigate = useNavigate();

  const handleProductClick = () => {
    if (productUri) {
      console.log('si paso por aqui');
      navigate(`/detalles-producto/${productUri}`);
    }
  };

  return (
    <article onMouseUp={handleProductClick}>
      <div>
        {<Figure image={product.desktop_image} />}
        <i
          className={!product.favorite ? "icon-Hearty" : "icon-HeartFill"}
          onClick={(e) => {
            e.stopPropagation();
            AddToHeart();
          }}
        ></i>
        <i className="icon-Share"></i>
      </div>
      <div>
        <div className="ItemDesc">
          <br />
          <br />
          <ul>
            <li>{product.category}</li>
            <li>{product.subcategory}</li>
          </ul>
          <h6>{product.name}</h6>
          <span>{product.price}</span>
          <em>Detalles del artículo</em>
          <p>{product.description}</p>
          <br />
          <br />
        </div>
      </div>
    </article>
  );
};

export default Component;
