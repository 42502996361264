import React from 'react';
const Component = ({ stored }) => {
	return (
		<label className='ItemDireccion'>
			{!stored && (
				<input type='radio' name='direccionenvio'></input>
			)}
			<div className={stored ? 'Selected' : ''}>
				<div>Dirección guardada 1</div>
				<div>Detalles de la dirección detalles de la dirección, detalles de la dirección.</div>
				<div className='EditarDireccion'>Editar</div>
			</div>
		</label>
	)
};

export default Component;