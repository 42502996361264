import React from 'react';
export const calculateSum = (a, b) => a + b;

export const multiply = (a, b) => a * b;

export const divide = (a, b) => {
	if (b === 0) {
		throw new Error("No se puede dividir por 0");
	}
	return a / b;
};
export const AddToHeart = (event) => {
	event.target.classList.toggle('icon-Hearty');
	event.target.classList.toggle('icon-HeartFill');
}