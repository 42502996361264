import React from 'react';
import { VISAIcon, MastercardIcon, PayPalIcon, OXXOIcon } from './Icons';
const Component = ({ stored, tarjetaicon, numero }) => {
	const tarjetas = {
		VISAIcon: <VISAIcon />,
		MastercardIcon: <MastercardIcon />,
		PayPalIcon: <PayPalIcon />,
		OXXOIcon: <OXXOIcon />
	}
	return (
		<label className='ItemTarjeta'>
			{!stored && (
				<input type='radio' name='tarjeta'></input>
			)}
			<div className={stored ? 'Selected' : ''}>
				<div>
					{tarjetas[tarjetaicon]}
				</div>
				<div>
					<div>{numero || '...'}</div>
					<div className='EditarTarjeta'>Editar</div>
				</div>
			</div>
		</label>
	)
};

export default Component;