import React from "react";
import { useNavigate, Link } from "react-router-dom";

const Component = () => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        Ingresa el correo electrónico que utilizaste al registrarte para
        recuperar tu contraseña. Recibirás un enlace para restablecer tu
        contraseña .
      </div>
      <label className="FormData">
        <em className="RequiredData">Email</em>
        <div className="FormInput">
          <input placeholder="Email" />
        </div>
      </label>
      <div
        className="ButtonLightMedium"
        onClick={() => navigate("/login-confirmar-recuperar")}
      >
        RECUPERAR CONTRASEÑA
        <i className="icon-ArrowRight"></i>
      </div>
    </>
  );
};

export default Component;
