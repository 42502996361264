import React from "react";
import { useNavigate, Link } from "react-router-dom";
import AgregadoItem from "./AgregadoItem";
import PodriaInteresarte from "./PodriaInteresarte";

const Component = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="Carrito">
        <div className="CarritoList">
          <h4>Carrito de compra</h4>
          {<AgregadoItem />}
          {<AgregadoItem />}
          {<AgregadoItem />}
        </div>
        <div className="CarritoResume">
          <b>Código de descuento</b>
          <p className="FontMedium">
            Si cuentas con un código de descuento, ingrésalo aquí.
          </p>
          <div className="Row">
            <div className="FormInput">
              <input placeholder="Código de descuento" />
            </div>
            <div className="ButtonDarkMedium">APLICAR</div>
          </div>
          <div className="Row">
            <p>Productos totales</p>
            <h6>3</h6>
          </div>
          <div className="Row">
            <h5>Total:</h5>
            <h5>$00.00</h5>
          </div>
          <div
            className="ButtonDark"
            onClick={() => navigate("/checkout-envio")}
          >
            REALIZAR COMPRA
            <i className="icon-Check"></i>
          </div>
        </div>
      </section>
      <PodriaInteresarte />
    </>
  );
};
export default Component;
