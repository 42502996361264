import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { Image7 } from "../../components/Images";
const Component = ({ noedit, pedido }) => {
  const navigate = useNavigate();
  return (
    <div className="AgregadoItem">
      <div>
        <div
          className="AgregadoItemImg"
          style={{ backgroundImage: `url(${Image7})` }}
        ></div>
        <div>
          <p>Nombre del articulo</p>
          <em>
            <b>Talla: </b>M
          </em>
          {/* <b></b> + <span></span> para fecha */}
          {pedido ? (
            <em>
              <b>Fecha de la compra: </b>
              <span>02 Noviembre 2023</span>
            </em>
          ) : (
            ""
          )}
          <em>Detalles del artículo</em>
        </div>
      </div>
      <div>
        <b>$00.00</b>
        {pedido ? (
          <>
            <div className="ButtonDarkThiny icon-Trolley">Volver a comprar</div>
            <div className="ButtonLightThiny icon-File">Solicitar factura</div>
          </>
        ) : (
          <>
            <div className="ButtonDarkThiny icon-Trash">Eliminar</div>
            {!noedit && (
              <div
                className="ButtonLightThiny icon-Edit"
                onClick={() => navigate("/agregado-carrito")}
              >
                Editar
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Component;
