import React from 'react';

const Component = () => {
	function CloseModal() {
		document.querySelector('.ProductoAgotado').classList.add('Hide');
	}
	function TeAvisaremos() {
		document.querySelector('.TeAvisaremos').classList.remove('Hide');
		CloseModal();
	}
	return (
		<div className='ProductoAgotado'>
			<div>
				<i className='icon-Closed' onClick={CloseModal}></i>
				<h4>Producto agotado.</h4>
				<p>Este producto está agotado. Puedes introducir tu email y te enviaremos un correo cuando el producto esté disponible de nuevo.</p>
				<div className='FormInput'>
					<input placeholder='Dirección de email' />
				</div>
				<div className='ButtonDarkMedium' onClick={TeAvisaremos}>
					<i className='icon-Bell'></i>
					GUARDAR
				</div>
			</div>
		</div>
	);
};

export default Component;