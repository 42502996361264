import React from 'react';
const Component = () => {
	const TogglePassword = (event) => {
		event = event.target;
		event.classList.toggle('icon-EyeClosed');
		event.classList.toggle('icon-EyeOpen');
		event = event.previousElementSibling;
		if (event.toggleAttribute('type')) {
			event.type = 'password';
		}
	};
	return (
		<>
			{/* if not <> -> use className='DataBox' */}
			<div className='TextLeft'>
				<p>Ingresa tu nueva contraseña</p>
			</div>
			{/**
			  *
			  * ! InvalidInput className in label-> error input style
			  *
			  **/}
			<label className='FormData'>
				<em className='RequiredData'>Nueva contarseña</em>
				<div className='FormInput'>
					<input placeholder='Nueva contarseña'
						type='password' />
					<i className='InputIcon icon-EyeClosed' onClick={TogglePassword}></i>
				</div>
				<em className='InfoData'>Contraseña incorrecta</em>
			</label>
			<label className='FormData'>
				<em className='RequiredData'>Repetir nueva contarseña</em>
				<div className='FormInput'>
					<input placeholder='Repetir nueva contarseña'
						type='password' />
					<i className='InputIcon icon-EyeClosed' onClick={TogglePassword}></i>
				</div>
				<em className='InfoData'>Contraseña incorrecta</em>
			</label>
			<div className='ButtonLightMedium'>
				CAMBIAR CONTRASEÑA
				<i className='icon-ArrowRight'></i>
			</div>
		</>
	);
};

export default Component;