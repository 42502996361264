import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
/**
 *
 *
 *  ?  Auth
 *
 *
 */
import Auth from "../pages/Auth";
/**
 *
 *
 *  ?  ComingSoon
 *
 *
 */
import ComingSoon from "../pages/ComingSoon";

//import { useNavigate } from "react-router-dom";
//const navigate = useNavigate();
//navigate("/");
//<Navigate to="/" />;

/**
 *
 *
 *  ?  Home
 *
 *
 */
//import Home from "./pages/Home";
import Header from "./Header";
import Navigation from "./Navigation";
import Footer from "./Footer";
import HomeIndex from "../pages/home/HomeIndex";
/**
 *
 *
 *  ?  Home pages
 *
 *
 */
import Colecciones from "../pages/home/Colecciones";
import Resultados from "../pages/home/Resultados";
import ResultadosZero from "../pages/home/ResultadosZero";
import DetallesProducto from "../pages/home/DetallesProducto";
import AgregadoCarrito from "../pages/home/AgregadoCarrito";
import Carrito from "../pages/home/Carrito";
/**
 *
 *
 *  ?  Checkout
 *
 *
 */
import DireccionEnvio from "../pages/checkout/DireccionEnvio";
import ResumenPedido from "../pages/checkout/ResumenPedido";
import InformacionPago from "../pages/checkout/InformacionPago";
import CompraExitosa from "../pages/checkout/CompraExitosa";
/**
 *
 *
 *  ?  Perfil
 *
 *
 */
import WishList from "../pages/perfil/WishList";
import MisPedidos from "../pages/perfil/MisPedidos";
import MiPerfil from "../pages/perfil/MiPerfil";
/**
 *
 *
 *  ?  Static
 *
 *
 */
import SobreNosotros from "../pages/static/SobreNosotros";
import TermsConds from "../pages/static/TermsConds";
import TablaTallas from "../pages/static/TablaTallas";
import Contacto from "../pages/static/Contacto";
import Error404 from "../pages/static/Error404";
import ListaSubcategorias from "../pages/home/ListaSubcategorias";
import ResultadosSubcategorias from "../pages/home/ResultadosSubcategorias";
import ListadoColecciones from "../pages/home/ListadoColecciones";

export default function AppRoutes() {
  const location = useLocation().pathname;
  const isWlcmLogin = location == "/" || /^\/login/.test(location);
  const isCheckout = /^\/checkout/.test(location);
  /**
   *
   * ? Header Bubble -> <Header wishlist='3' />
   *
   * ! Checkout header and footer only
   *
   *
   */
  return (
    <>
      {!isWlcmLogin && <Header wishlist="3" />}
      {!isWlcmLogin && !isCheckout && <Navigation />}
      <Routes>
        <Route path="/" element={<ComingSoon />} />
        <Route path="/home" element={<HomeIndex />} />
        <Route path="/terminos-y-condiciones" element={<TermsConds />} />
        <Route path="/about" element={<SobreNosotros />} />
        <Route path="/tabla-de-tallas" element={<TablaTallas />} />
        <Route
          path="/resultados"
          element={
            <Resultados
              title="Estos artículos coinciden con tu búsqueda"
              databc={["T-Shirts"]}
            />
          }
        />
        <Route
          path="/sin-resultados"
          element={<ResultadosZero databc={["T-Shirts"]} />}
        />
        <Route path="/wishlist" element={<WishList />} />
        <Route path="/mis-pedidos" element={<MisPedidos />} />
        <Route path="/mi-perfil" element={<MiPerfil />} />
        <Route path="/carrito" element={<Carrito />} />
        <Route path="/checkout-envio" element={<DireccionEnvio />} />
        <Route path="/checkout-resumen" element={<ResumenPedido />} />
        <Route path="/checkout-pago" element={<InformacionPago />} />
        <Route path="/checkout-exito" element={<CompraExitosa />} />
        <Route
          path="/detalles-producto/:uri"
          element={
            <DetallesProducto />
          }
        />
        <Route path="/404" element={<Error404 />} />

        {/* CATEGORIAS Y SUBCATEGORIAS */}
        <Route path="/category/:categoria" element={<ListaSubcategorias />} />
        <Route path="/category/:categoria/:subcategoria/:colecc?" element={<ResultadosSubcategorias />} />

        {/* COLECCIONES */}
        <Route path="/colecciones/:coleccion?" element={<ListadoColecciones />} />


        <Route path="/contacto" element={<Contacto />} />
        <Route
          path="/agregado-carrito"
          element={
            <AgregadoCarrito
              carritoinfo={{ cantidad: 3, total: "3,358", moneda: "MXN" }}
            />
          }
        />
        <Route path="/login" element={<Auth page="IniciarSesionWith" />} />
        <Route path="/login-crear" element={<Auth page="CrearCuenta" />} />
        <Route
          path="/login-recuperar"
          element={<Auth page="RestablecerPassword" />}
        />
        <Route path="/login-email" element={<Auth page="IniciarSesionData" />} />
        <Route path="/login-cambiar" element={<Auth page="CambiarPassword" />} />
        <Route
          path="/login-confirmar-registro"
          element={<Auth page="ConfirmarRegistro" />}
        />
        <Route
          path="/login-confirmar-recuperar"
          element={<Auth page="ConfirmarCorreoRecuperar" />}
        />
        <Route path="/login-exito" element={<Auth page="RegistroExitoso" />} />
      </Routes>
      {!isWlcmLogin && <Footer />}
    </>
  );
}
