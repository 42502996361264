import React from "react";
import { useNavigate, Link } from "react-router-dom";
import ItemDireccion from "../../components/ItemDireccion";
const Component = () => {
  const navigate = useNavigate();
  return (
    <section className="CheckoutBox">
      <article>
        <div className="StepCheckout">
          <div className="Selected"></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="icon-HouseLine"></div>
        <h5>Dirección de envío</h5>
        <div className="DireccionesGuardadas">
          <div>Direcciones guardadas</div>
          <div className="FontSmall">
            Selecciona la dirección a donde enviaremos tu compra.
          </div>
          {<ItemDireccion />}
          {<ItemDireccion />}
          <div className="ButtonLightMedium">
            <i>+</i>
            AGREGAR OTRA DIRECCIÓN
          </div>
        </div>
        <div className="FormDireccion">
          <div>Agregar dirección de envío</div>
          <div className="FontSmall">
            Indica la dirección a donde enviaremos tu compra.
          </div>
          <label className="FormData">
            <em className="RequiredData">Calle, Número, Apartamento</em>
            <div className="FormInputLight">
              <input placeholder="Calle, Número, Apartamento" />
            </div>
          </label>
          <div className="Row">
            <label className="FormData">
              <em className="RequiredData">Código postal</em>
              <div className="FormInputLight">
                <input placeholder="Código postal" />
              </div>
            </label>
            <label className="FormData">
              <em className="RequiredData">Ciudad</em>
              <div className="FormInputLight">
                <input placeholder="Ciudad" />
              </div>
            </label>
          </div>
          <label className="FormData">
            <em className="RequiredData">País</em>
            <div className="FormInputLight">
              <input placeholder="País" />
              <i className="InputIcon icon-Select"></i>
            </div>
          </label>
          <label className="FormData">
            <em>Detalles</em>
            <div className="FormInputLight">
              <textarea placeholder="Agrega detalles de la dirección"></textarea>
            </div>
          </label>
          <div className="ButtonLightMedium">GUARDAR DIRECCIÓN</div>
        </div>
        <div
          className="ButtonDarkMedium"
          onClick={() => navigate("/checkout-resumen")}
        >
          CONTINUAR
          <i className="icon-Check"></i>
        </div>
      </article>
    </section>
  );
};
export default Component;
