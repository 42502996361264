import React from 'react';
import PodriaInteresarte from '../home/PodriaInteresarte';
import ColeccionItem from '../home/ColeccionItem';
import { Person9, Image5, Person11, Image9 } from '../../components/Images';
const Component = () => {
	const inwishlist = [
		[Person9],
		[Image5],
		[Person11]
	];
	return (
		<>
			<div className='WishList'>
				<h3>Wishlist</h3>
				<p>{inwishlist.length} articulos</p>
			</div>
			<section className='ColeccionItems ColeccionVertical WishList'>
				{inwishlist.map((item, index) => (
					<ColeccionItem key={index} image={item} heart />
				))}
			</section>
			<PodriaInteresarte />
		</>
	);
};

export default Component;