import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from '../fetch/axios';

const Component = () => {
  const [dragStartX, setDragStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const contentRef = useRef(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('/categories/header');
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories', error);
      } finally {
        setLoadingCategories(false);
      }
    };
    fetchCategories();
  }, []);

  // useEffect(() => {
  //   const handleMove = (clientX) => {
  //     if (dragStartX !== 0) {
  //       const dx = clientX - dragStartX;
  //       setScrollLeft((prevMargin) => {
  //         const totalWidth = contentRef.current.scrollWidth;
  //         const containerWidth = contentRef.current.clientWidth;
  //         const newMargin = prevMargin - dx;
  //         return Math.min(0, Math.max(containerWidth - totalWidth, newMargin));
  //       });
  //       setDragStartX(clientX);
  //     }
  //   };
  //   const handleMouseMove = (e) => handleMove(e.clientX);
  //   const handleTouchMove = (e) => handleMove(e.touches[0].clientX);

  //   const handleEnd = () => setDragStartX(0);

  //   window.addEventListener("mousemove", handleMouseMove);
  //   window.addEventListener("mouseup", handleEnd);
  //   window.addEventListener("touchmove", handleTouchMove);
  //   window.addEventListener("touchend", handleEnd);

  //   return () => {
  //     window.removeEventListener("mousemove", handleMouseMove);
  //     window.removeEventListener("mouseup", handleEnd);
  //     window.removeEventListener("touchmove", handleTouchMove);
  //     window.removeEventListener("touchend", handleEnd);
  //   };
  // }, [dragStartX]);
  const handleStart = (clientX) => setDragStartX(clientX);
  // const handleMouseDown = (e) => handleStart(e.clientX);
  // const handleTouchStart = (e) => handleStart(e.touches[0].clientX);
  return (
    <nav
      className="Navigation"
      // onMouseDown={handleMouseDown}
      // onTouchStart={handleTouchStart}
    >
      <ul
        ref={contentRef}
        style={{
          left: `${scrollLeft}px`,
        }}
      >
        {loadingCategories ? <div>Loading...</div> : categories.map((category) => (
          <li key={category.id}>
            <Link to={`/category/${category.uri}`}>{category.name}</Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Component;
