import React from "react";
import { useNavigate, Link } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import ItemTarjeta from "../../components/ItemTarjeta";
import ItemDireccion from "../../components/ItemDireccion";
import { Person1 } from "../../components/Images";
const Component = () => {

  const navigate = useNavigate();
  return (
    <>
      <div className="PerfilContent">
        <Breadcrumb title="MiPerfil" perfil />

        <div className="grid-title">
          <div className="ArtFans BlackIconPerfil ArtFansPerfil"></div>
          <span></span>
          <h2>Mi Perfil</h2>
        </div>
        
        {/* <section className="PerfilInfo">
          <div className="PerfilBox">
            <div className="ProfilePic">
              <div>
                <div
                  className="ProfileImage"
                  style={{ backgroundImage: `url(${Person1})` }}
                ></div>
                <div>
                  <h5>Rafael Sánchez</h5>
                  <em className="EditarPerfil FontMedium">Editar</em>
                </div>
              </div>
              <div
                className="ButtonDark ButtonContent"
                onClick={() => navigate("/mis-pedidos")}
              >
                MIS PEDIDOS
              </div>
            </div>
            <div className="ProfileCard">
              <div className="Card">
                <div className="ArtFans"></div>
                <em className="FontSmall">Categoría:</em>
                <h3>AMBER</h3>
                <div className="FontMedium">
                  <span>Número de afiliación</span>
                  <span>1234 57680</span>
                </div>
              </div>
              <div className="Level">
                <div>
                  Estas muy cerca de desbloquear la categoría: <b>Esmerald</b>
                </div>
                <div>
                  <div className="Selected"></div>
                  <div className="Selected"></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <hr />
        <section className="PerfilData">
          <article>
            <div className="grid">
              <div className="icon-IdentificationCard"></div>
              <b>Mis Datos</b>
            </div>
            <div className="ItemData">
              <div className="Selected">
                <div><strong>Nombre:</strong> Rafael Sánchez</div>
              </div>
            </div>
            <div className="ItemData">
              <div className="Selected">
                <div><strong>Télefono:</strong> +52 00 0000 0000</div>
              </div>
            </div>
            <div className="ItemData">
              <div className="Selected">
                <div><strong>Email:</strong> mail@ejemplo.com</div>
              </div>
            </div>
            <div className="ItemData">
              <div className="Selected">
                <div><strong>No. Afiliación:</strong> 1234 57680 </div>
              </div>
            </div>
            <div className="ItemData">
              <div className="Selected">
                <div><a href="#">Editar datos</a></div>
              </div>
            </div>
          </article>
          <article>
            <div className="grid">
              <div className="icon-Package"></div>
              <b>Pedidos <em>Rastrear paquetes, devolver pedidos o comprar algo de nuevo.</em></b>
            </div>
            
            
            <div className="ButtonLight ButtonContent SeePackages"  onClick={() => navigate("/mis-pedidos")}>
            VER MIS PEDIDOS
            </div>
          </article>
          <article>
            <div className="grid">
              <div className="icon-HouseLine"></div>
              <b>Direcciones guardadas <em>Direcciones guardadas para entrega de tus compras.</em></b>
            </div>
            
            {<ItemDireccion stored />}
            {<ItemDireccion stored />}
            <div className="ButtonLight ButtonContent">
              <i>+</i>
              AGREGAR OTRA DIRECCIÓN
            </div>
          </article>
          
        </section>
      </div>
    </>
  );
};

export default Component;
