import React from "react";
import { AddToHeart } from "../../components/Funciones";
import FigureProduct from "../../components/FigureProduct";
const ProductoResultado = ({ producto }) => {
  return (
    <>
      <article>
        {/* <div style={{ backgroundImage: `url(${image})` }}> */}
        <div>
          {<FigureProduct imagedesktop={producto.desktop_image} imagemovile={producto.movile_image} uri={producto.uri} />}
          <i
            className={!producto.favorite ? "icon-Hearty" : "icon-HeartFill"}
            onClick={AddToHeart}
          ></i>
          <i className="icon-Share"></i>
        </div>
        <div>
          <div className="ItemDesc">
            <br />
            <br />
            <ul>
              <li>{producto.category}</li>
              <li>{producto.subcategory}</li>
            </ul>
            <h6>{producto.name}</h6>
            <span>${producto.price}</span>
            <em>{producto.description}</em>
            {/* <p>
              Sorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
              nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
              tellus elit sed risus. Maecenas eget condimentum velit, sit amet
              feugiat lectus.
            </p> */}
            <br />
            <br />
          </div>
        </div>
      </article>
    </>
  );
};

export default ProductoResultado;
