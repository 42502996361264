import React from "react";
import { useNavigate, Link } from "react-router-dom";
import PodriaInteresarte from "./PodriaInteresarte";
import AgregadoItem from "./AgregadoItem";
const Component = ({ carritoinfo = {} }) => {
  const navigate = useNavigate();
  return (
    <>
      <section className="AgregadoCarrito">
        <div>
          <div className="icon-Check"></div>
          <h3>
            Producto agregado
            <br /> al carrito
          </h3>
          {<AgregadoItem />}
          <p className="FontMedium">
            Hay {carritoinfo.cantidad} artículos en tu carrito con un total de:
            ${carritoinfo.total} {carritoinfo.moneda}
          </p>
          <div
            className="ButtonDarkMedium"
            onClick={() => navigate("/carrito")}
          >
            <i className="icon-Trolley"></i>
            VER CARRITO
          </div>
          <div
            className="ButtonLightMedium"
            onClick={() => navigate("/category")}
          >
            SEGUIR COMPRANDO
          </div>
        </div>
      </section>
      <PodriaInteresarte />
    </>
  );
};

export default Component;
