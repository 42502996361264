import React from "react";
import { Link } from "react-router-dom";
const Component = () => {
  return (
    <>
      <div className="icon-Mail IconLarge"></div>
      <div className="TextLeft">
        <p>
          Hemos enviado un correo electrónico a example@mail.com, con un enlace
          para recuperar tu contraseña.
        </p>
        <p>
          Si no encuentras el mensaje en tu bandeja principal, revisa las
          bandejas de spam y promociones.
        </p>
      </div>
      <div>
        <p>¿No recibiste el correo de confirmación?</p>
        <Link to="/login-confirmar-recuperar">
          <b>Reenviar correo</b>
        </Link>
      </div>
      <div>
        <Link to="/login-confirmar-recuperar">
          <b>Enviar SMS</b>
        </Link>
      </div>
    </>
  );
};

export default Component;
