import React from "react";
import { useNavigate, Link } from "react-router-dom";
const Component = () => {
  const navigate = useNavigate();
  return (
    <section className="CheckoutBox CompraExitosa">
      <article>
        <div className="StepCheckout">
          <div className="Selected"></div>
          <div className="Selected"></div>
          <div className="Selected"></div>
          <div className="Selected"></div>
        </div>
        <div className="icon-Check"></div>
        <h5>¡Compra exitosa!</h5>
        <b>Tu pago fue realizado con éxito</b>
        <div className="FontSmall">
          Hemos enviado un email a email@ejemplo.com
          <br /> con los detalles de tu compra.
        </div>
        <div className="Row">
          <div
            className="ButtonLightMedium"
            onClick={() => navigate("/mis-pedidos")}
          >
            VER MIS PEDIDOS
          </div>
          <div className="ButtonDarkMedium" onClick={() => navigate("/home")}>
            SEGUIR COMPRANDO
            <i className="icon-Check"></i>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Component;
