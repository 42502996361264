import React from "react";
import { Person4 } from "../../components/Images";
const Component = () => {
  const nombre = "Rafael Rodríguez";
  return (
    <>
      <section className="Contacto ImageButton">
        <article
          className="ImageButtonBG"
          style={{ backgroundImage: `url(${Person4})` }}
        >
          <div>
            <div className="ButtonContent ButtonDark">CONTACTO</div>
          </div>
        </article>
        <article className="ContactoBox">
          <div>
            <h5>Envíanos un mensaje</h5>
            <label className="FormData">
              <em className="RequiredData">Nombre</em>
              <div className="FormInputLight">
                <input placeholder="Nombre" value={nombre} />
              </div>
            </label>
            <label className="FormData">
              <em className="RequiredData">Email</em>
              <div className="FormInputLight">
                <input placeholder="Email" />
              </div>
            </label>
            <label className="FormData">
              <em className="RequiredData">Mensaje</em>
              <div className="FormInputLight">
                <textarea placeholder="Escribe un mensaje"></textarea>
              </div>
            </label>
            <div className="ButtonContent ButtonDarkMedium">ENVIAR</div>
          </div>
          <div className="TextCenter">
            <b className="FontNormal">¡Síguenos!</b>
            <div>
              <i className="icon-Facebook"></i>
              <i className="icon-Instagram"></i>
              <i className="icon-Tiktok"></i>
              <i className="icon-X"></i>
            </div>
          </div>
        </article>
      </section>
    </>
  );
};

export default Component;
