import React, { useRef, useEffect, useState } from "react";
import instance from "../../fetch/axios";
import $ from "jquery"; // Importación de jQuery
import { useNavigate, useParams } from "react-router-dom";
import { AddToHeart } from "../../components/Funciones";
import Breadcrumb from "../../components/Breadcrumb";
import ProductoAgotadoBox from "./ProductoAgotadoBox";
import PodriaInteresarte from "./PodriaInteresarte";
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import { Fancybox } from "@fancyapps/ui";
import imgLayer from "../../assets/images/layer_gr.png";
import MigajasCategorias from "../../components/MigajasCategorias";

const LoadingCard = () => {
  return (
    <>
    <div className="col-md-3">
      <div className="wrapper">
        <div className="card-loader card-loader--tabs"></div>
      </div>
    </div>
</>
  );
};

const DetallesProducto = () => {
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [thumbnailActive, setThumbnailActive] = useState(0);
  const [tallasActive, setTallasActive] = useState(0);
  const [selectedTalla, setSelectedTalla] = useState("");
  const { uri } = useParams();
  const ThumbnailRef = useRef(null);
  const TallasRef = useRef(null);
  const GranImagen = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    instance.get(`/products/detail?uri=${uri}`)
      .then((response) => {
        setProduct(response.data);
        setSelectedTalla(response.data.talla);
        setTallasActive(CurrentPosTalla(response.data.sizes, response.data.talla));
      })
      .catch((error) => console.error("Error fetching product data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, [uri]);

  useEffect(() => {
    // Código jQuery para gestionar eventos después de renderizar el DOM
    $(".Thumbnail li").on("click", function () {
      const backgroundImage = $(this).css("background-image");
      $(this).siblings().removeClass("Selected");
      $(this).addClass("Selected");
      $(GranImagen.current).css("background-image", backgroundImage);
    });

    Fancybox.bind("[data-fancybox]", {
      // Opciones de Fancybox si
    });

    // Cleanup: elimina listeners para evitar fugas de memoria
    return () => {
      $(".Thumbnail li").off("click");
      Fancybox.unbind("[data-fancybox]"); // Limpieza si es necesario
    };
  }, []);

  if (isLoading || !product) {
    return (
      <div className="loading-container">
        <LoadingCard />
      </div>
    );
  }

  const thumbnails = product.gallery.map((img) => img.thumb);
  const ImageActiveIndex = 0;

  function Thumbnail() {
    return (
      <ul ref={ThumbnailRef}>
        {thumbnails.map((image, index) => (
          <li
            key={index}
            style={{ backgroundImage: `url(${image})` }}
            className={index === ImageActiveIndex ? "Selected" : ""}
          ></li>
        ))}
      </ul>
    );
  }

  function ThumbnailPos(plus) {
    let newThumbnailActive = thumbnailActive;
    if (plus === 1 && newThumbnailActive < thumbnails.length - 1) {
      newThumbnailActive += 1;
    }
    if (plus !== 1 && newThumbnailActive > 0) {
      newThumbnailActive -= 1;
    }
    setThumbnailActive(newThumbnailActive);

    const selectedThumbnail = thumbnails[newThumbnailActive];
    $(GranImagen.current).css("background-image", `url(${selectedThumbnail})`);

    Array.from(ThumbnailRef.current.children).forEach((thumbnail, index) => {
      thumbnail.classList.remove("Selected");
      if (newThumbnailActive === index) {
        thumbnail.classList.add("Selected");
      }
    });
  }

  function CurrentPosTalla(sizes, talla) {
    return sizes.findIndex((obj) => obj.letter === talla);
  }

  function TallasPos(plus) {
    let newTallasActive = tallasActive;
    let tallasParent = document.querySelector(".SeleccionaTalla");
    if (plus === 1 && newTallasActive < product.sizes.length - 4) {
      newTallasActive += 1;
    }
    if (plus !== 1 && newTallasActive > 0) {
      newTallasActive -= 1;
    }
    setTallasActive(newTallasActive);

    let extra = 1.15 * (newTallasActive - 1);
    extra = extra < 0 ? 0 : extra;
    let left = 26 * newTallasActive;
    left -= extra;
    TallasRef.current.firstElementChild.style.marginLeft = `-${left}%`;
  }

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleTallaClick = (talla) => {
    setSelectedTalla(talla);
  };

  return (
    <>
      <MigajasCategorias 
      nivel1={product.category ? product.category : 'loading...'} 
      nivel2={product ? product.name : ''} 
      redirect={product.category_uri}
      />

      {product.sizes.every(size => size.quantity === 0) && <ProductoAgotadoBox />}
      <section className="DetallesProducto">
        <div>
          {/* ColeccionItems */}
          <div className="ProductoImagenes">
            <div
              ref={GranImagen}
              style={{
                backgroundImage: `url(${thumbnails[ImageActiveIndex]})`,
              }}
            >
              {product.web_exclusive && (
                <div className="RotateFlag">
                  <div className="icon-CursorClick">WEB EXCLUSIVE</div>
                  <div className="icon-CrownSimple">LIMITED EDITION</div>
                </div>
              )}
              <div>
                <i className="icon-Hearty" onClick={AddToHeart}></i>
                <i className="icon-Share"></i>
              </div>
            </div>
            <div className="Thumbnail" data-i={thumbnailActive}>
              <div className="icon-CaretLeft" onClick={() => ThumbnailPos(-1)}></div>
              {Thumbnail()}
              <div
                className="icon-CaretRight"
                onClick={() => ThumbnailPos(1)}
              ></div>
            </div>
          </div>
          <div className="ProductoDescripcion">
            <div>
              <div className="ItemDesc">
                {product.sizes.every(size => size.quantity === 0) && (
                  <div className="NoDisponible">Producto NO DISPONIBLE</div>
                )}
                <h4>{product.name}</h4>
                <ul>
                  <li>{product.collection}</li>
                </ul>
                <h6>{product.price}</h6>
                <em>{product.description}</em>
              </div>
              {/* <div className="SeleccionaColor" >
                <h6>Seleccionar color:</h6>
                <div>
                  <div className="grid">
                    <button className="color-1"></button>
                    <button className="color-2"></button>
                    <button className="color-3"></button>
                    <button className="color-4"></button>
                  </div>
                </div>
              </div> */}
              <div className="SeleccionaCantidad" >
                <h6>Cantidad:</h6>
                <div>
                  <div className="counter">
                    <button className="counter__btn counter__btn--decrement" onClick={handleDecrement}>-</button>
                    <input type="text" className="counter__input" value={quantity} readOnly />
                    <button className="counter__btn counter__btn--increment" onClick={handleIncrement}>+</button>
                  </div>
                </div>
              </div>
                  
              <div className="EncuentraTalla">
                <a
                  href="#modal-talla" // Enlace al contenedor modal
                  data-fancybox // Atributo necesario para abrir con Fancybox
                  data-src="#modal-talla" // Referencia al modal
                  data-type="inline" // Especifica que es contenido inline
                >
                  <i className="icon-Shirt"></i>
                  <em>Encuentra tu talla</em>
                </a>
              </div>

              {/* Contenedor del modal */}
              <div id="modal-talla" className="modal-talla" style={{ display: "none", maxWidth: "700px" }}>
                <div className="modal-content">
                  <h2><i></i>Encuentra tu talla</h2>
                  <div className="text"><strong>Importante: </strong> Las medidas de las tallas pueden variar según el producto. Te invitamos a revisar la siguiente información para que elijas la talla ideal para ti.</div>
                  <img src={imgLayer} alt="Guía de tallas" />
                </div>
              </div>

              <div className="SeleccionaTalla" data-i={tallasActive}>
                <h6>Selecciona la talla:</h6>
                <div className="text"><strong>Importante: </strong> Las medidas de las tallas pueden variar según el producto. Te invitamos a revisar la sección <u>"Encuentra tu talla"</u> para que elijas la talla ideal para ti.</div>
                <div>
                  <div className="icon-CaretLeft" onClick={() => TallasPos(-1)}></div>
                  <ul ref={TallasRef}>
                    {product.sizes.map((item, index) => (
                      <li
                        key={index}
                        className={`${
                          item.letter === selectedTalla ? "Selected" : ""
                        } ButtonLightMedium ${
                          item.quantity > 0 ? "" : "Disabled"
                        }`}
                        onClick={() => handleTallaClick(item.letter)}
                      >
                        {item.letter}
                      </li>
                    ))}
                  </ul>
                  <div
                    className="icon-CaretRight"
                    onClick={() => TallasPos(1)}
                  ></div>
                </div>
              </div>
              {product.sizes.some(size => size.quantity > 0) ? (
                <>
                  <div className="ButtonDarkMedium">
                    <i className="icon-Trolley"></i>
                    AGREGAR AL CARRITO
                  </div>
                  <div
                    className="ButtonLightMedium"
                    onClick={() => navigate("/checkout-envio")}
                  >
                    COMPRAR AHORA
                  </div>
                </>
              ) : (
                <div className="ButtonDarkMedium">
                  <i className="icon-Bell"></i>
                  AVISARME CUANDO ESTE DISPONIBLE
                </div>
              )}
              <div className="TeAvisaremos Hide">
                <i className="icon-Check"></i>
                <span>
                  Te enviaremos un correo cuando este producto esté disponible.
                </span>
              </div>
              <div>
                <h6>Detalles del producto:</h6>
                <p dangerouslySetInnerHTML={{ __html: product.detail }} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <PodriaInteresarte 
        title="Productos relacionados" 
        related={product.related} 
      />
    </>
  );
};
export default DetallesProducto;
