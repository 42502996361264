import React, { useRef } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import ColeccionItem from "./ColeccionItem";
import { Person9, Image5, Person11, Image9 } from "../../components/Images";

const Colecciones = ({ title, coleccionesMenu, databc = [], noposition, products = [], totalPages = 1, currentPage = 1, onPageChange }) => {
  const coleccionPosition = useRef(null);
  function SetPositionColeccion(event) {
    const pos = ["Vertical", "Horizontal"];
    pos[2] = event.target.dataset.n.split(",");
    event.target.parentNode
      .querySelector(`.icon-${pos[pos[2][0]]}`)
      .classList.add("Selected");
    event.target.parentNode
      .querySelector(`.icon-${pos[pos[2][1]]}`)
      .classList.remove("Selected");
    coleccionPosition.current.classList.toggle("ColeccionHorizontal");
    coleccionPosition.current.classList.toggle("ColeccionVertical");
  }
  function ColeccionMenuItem(data) {
    return (
      <section className="ColeccionMenu">
        <div>
          {coleccionesMenu != 0 && (
            <>
              <p>Colecciones:</p>
              <ul>
                <li className="Selected">Todas</li>
                <li>Pride</li>
                <li>Abstract</li>
                <li>Animals</li>
                <li>Love</li>
                <li>Ocean</li>
                <li>Senses</li>
              </ul>
            </>
          )}
        </div>
        <div>
          <span
            className="icon-Vertical"
            data-n="0,1"
            onClick={SetPositionColeccion}
          >
            Vertical
          </span>
          <span
            className="icon-Horizontal Selected"
            data-n="1,0"
            onClick={SetPositionColeccion}
          >
            Horizontal
          </span>
        </div>
      </section>
    );
  }
  function ColeccionPagination() {
    if (totalPages <= 1) return null;
    
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <li 
          key={i} 
          className={currentPage === i ? 'Selected' : ''} 
          onClick={() => onPageChange(i)}
        >
          {i}
        </li>
      );
    }
    return <ul>{pages}</ul>;
  }

  return (
    <>
      <Breadcrumb title={title} databc={databc} />
      {!noposition && ColeccionMenuItem()}
      <section
        className="ColeccionItems ColeccionVertical"
        ref={coleccionPosition}
      >
        {products.map(product => (
          <ColeccionItem 
            key={product.id} 
            product={product}
            productUri={product.uri || product.id} // Pasamos el uri por separado
          />
        ))}
      </section>
      <section className="ColeccionPagination">
        {currentPage > 1 && (
          <div 
            className="icon-CaretLeft" 
            onClick={() => onPageChange(currentPage - 1)}
          />
        )}
        {ColeccionPagination()}
        {currentPage < totalPages && (
          <div 
            className="icon-CaretRight" 
            onClick={() => onPageChange(currentPage + 1)}
          />
        )}
      </section>
    </>
  );
};

export default Colecciones;
