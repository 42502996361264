import React, { useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
const Component = () => {
  const [emailValue, setEmail] = useState("");
  const passwordBoxRef = useRef(null);
  const [passwordValue, setPassword] = useState("");
  const TogglePassword = (event) => {
    event = event.target;
    event.classList.toggle("icon-EyeClosed");
    event.classList.toggle("icon-EyeOpen");
    event = event.previousElementSibling;
    if (event.toggleAttribute("type")) {
      event.type = "password";
    }
  };
  const CheckPassword = (event) => {
    setPassword(event.target.value);
    if (passwordValue) {
      passwordBoxRef.current.classList.remove("InvalidInput");
    }
  };
  const navigate = useNavigate();
  const Login = (event) => {
    event.preventDefault();
    passwordBoxRef.current.classList[!passwordValue ? "add" : "remove"](
      "InvalidInput"
    );
    if (passwordValue) {
      navigate("/mi-perfil");
    }
  };
  return (
    <>
      {/* if not <> -> use className='DataBox' */}
      <label className="FormData">
        <em className="RequiredData">Email</em>
        <div className="FormInput">
          <input
            placeholder="Email"
            value={emailValue}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </label>
      {/**
       *
       * ! InvalidInput className in label-> error input style
       *
       **/}
      <label className="FormData" ref={passwordBoxRef}>
        <em className="RequiredData">Contraseña</em>
        <div className="FormInput">
          <input
            placeholder="Contraseña"
            type="password"
            value={passwordValue}
            onChange={CheckPassword}
          />
          <i className="InputIcon icon-EyeClosed" onClick={TogglePassword}></i>
        </div>
        <em className="InfoData">Contraseña incorrecta</em>
      </label>
      <div className="ButtonLightMedium" onClick={Login}>
        INICIAR SESIÓN
        <i className="icon-ArrowRight"></i>
      </div>
      <div className="TextColorSec">
        <em>¿Olvidaste tu contraseña? </em>
        <Link to="/login-recuperar">
          <b>Recuperar contraseña</b>
        </Link>
      </div>
    </>
  );
};

export default Component;
