import React from 'react'

function LoadingCard() {
    return (
        <>
            <div className="col-md-3">
                <div className="wrapper">
                    <div className="card-loader card-loader--tabs"></div>
                </div>
            </div>
            <div className="col-md-3">
                <div className="wrapper">
                    <div className="card-loader card-loader--tabs"></div>
                </div>
            </div>
            <div className="col-md-3">
                <div className="wrapper">
                    <div className="card-loader card-loader--tabs"></div>
                </div>
            </div>
            <div className="col-md-3">
                <div className="wrapper">
                    <div className="card-loader card-loader--tabs"></div>
                </div>
            </div>
        </>


    )
}

export default LoadingCard
