import React from "react";
import { useNavigate, Link } from "react-router-dom";
import PodriaInteresarte from "../home/PodriaInteresarte";
const Component = () => {
  const navigate = useNavigate();
  return (
    <main>
      <section className="AgregadoCarrito">
        <div>
          <div className="icon-Check"></div>
          <h3>Registro exitoso</h3>
          <em>Rafael ¡Bienvenido a ArteOnU!</em>
          <div className="ButtonDarkMedium" onClick={() => navigate("/home")}>
            <i className="icon-Trolley"></i>
            VER TIENDA
          </div>
          <div
            className="ButtonLightMedium"
            onClick={() => navigate("/mi-perfil")}
          >
            VER MI PERFIL
          </div>
        </div>
      </section>
      <PodriaInteresarte title="Quizá puede interesarte" />
    </main>
  );
};

export default Component;
