import React from "react";
import { useNavigate } from "react-router-dom";
//import ArtOnU from '../assets/icons/ArtOnU.svg';
//import ComingSoonImage from '../assets/icons/ComingSoon.svg';
import { ArtOnUIcon, ComingSoonIcon } from "../components/Icons";
const ComingSoon = () => {
  const navigate = useNavigate();
  return (
    <main className="ComingSoon FontColorLight TextCenter">
      <div className="BlackGradient"></div>
      <div className="FrameComingSoon">
        <ArtOnUIcon />
        <div>
          <h5>OUR NEW WEBSITE IS</h5>
        </div>
        <ComingSoonIcon />
        <div className="FrameBorder" onClick={() => navigate("/home")}>
          <h5>
            NUESTRA PÁGINA ESTA
            <br />
            EN CONSTRUCCIÓN
          </h5>
        </div>
        <div className="ButtonLight">
          <i className="icon-Instagram"></i>
          ARTEONU
        </div>
      </div>
    </main>
  );
};

export default ComingSoon;
