import React from "react";
import { Link, useParams } from "react-router-dom";
const MigajasCategorias = ({ nivel1, nivel2,redirect=null }) => {

  const { categoria } = useParams();

  return (
    <div className={`CategoryTitle`}>
      <ul className="Breadcrumb">
        <li><Link to="/home">Home</Link></li>
        {nivel2 ?  <li><Link to={`/category/${redirect ? redirect : categoria}`}>{nivel1}</Link></li>  :
        <li>{nivel1}</li>}
        {nivel2 ? <li>{nivel2}</li> : ''}
      </ul>
      {<h3>{nivel2 ? nivel2:nivel1}</h3>}
    </div>
  );
};

export default MigajasCategorias;
