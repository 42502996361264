import React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import IniciarSesionWith from "./auth/IniciarSesionWith";
import IniciarSesionData from "./auth/IniciarSesionData";
import RestablecerPassword from "./auth/RestablecerPassword";
import ConfirmarCorreoRecuperar from "./auth/ConfirmarCorreoRecuperar";
import CambiarPassword from "./auth/CambiarPassword";
import CambiadoPassword from "./auth/CambiadoPassword";
import CrearCuenta from "./auth/CrearCuenta";
import ConfirmarRegistro from "./auth/ConfirmarRegistro";
import RegistroExitoso from "./auth/RegistroExitoso";
function Box(section) {
  const renderTitle = (title) => {
    return (
      <div>
        <h5>{title}</h5>
      </div>
    );
  };
  const titles = {
    IniciarSesionWith: renderTitle("Iniciar sesión"),
    IniciarSesionData: renderTitle("Iniciar sesión"),
    RestablecerPassword: renderTitle("Restablecer contraseña "),
    ConfirmarCorreoRecuperar: renderTitle("Restablecer contraseña "),
    CambiarPassword: renderTitle("Cambiar contraseña "),
    CrearCuenta: renderTitle("Crear cuenta"),
    ConfirmarRegistro: renderTitle("Confirma tu correo electrónico"),
  };
  const pages = {
    IniciarSesionWith: <IniciarSesionWith />,
    IniciarSesionData: <IniciarSesionData />,
    RestablecerPassword: <RestablecerPassword />,
    ConfirmarCorreoRecuperar: <ConfirmarCorreoRecuperar />,
    CambiarPassword: <CambiarPassword />,
    CambiadoPassword: <CambiadoPassword />,
    CrearCuenta: <CrearCuenta />,
    ConfirmarRegistro: <ConfirmarRegistro />,
    RegistroExitoso: <RegistroExitoso />,
  };
  const footer = (section) => {
    /**
     *
     * ! className='TextColorSec'
     * ? show lines inside as linear gris
     *
     */
    if (
      section == "ConfirmarCorreoRecuperar" ||
      section == "CambiarPassword" ||
      section == "CambiadoPassword" ||
      section == "CrearCuenta" ||
      section == "ConfirmarRegistro"
    ) {
      return null;
    }
    return (
      <div className={section == "RestablecerPassword" ? "TextColorSec" : ""}>
        <p>¿Aún no estas registrado?</p>
        <Link to="/login-crear">
          <b>Crear cuenta</b>
        </Link>
      </div>
    );
  };
  return (
    <>
      {section == "CambiadoPassword" ? null : titles[section]}
      {pages[section]}
      {footer(section)}
    </>
  );
}
function ReturnAuth(page) {
  const navigate = useNavigate();
  return page == "RegistroExitoso" ? (
    <RegistroExitoso />
  ) : (
    <main className="ContentBox ContentChildCenter FontColorLight TextCenter">
      <div className="IniciarSesion">
        <div
          className="ImageIcon ArtOnU"
          onClick={() => navigate("/home")}
        ></div>
        <div
          className="ImageIcon ArtFans"
          onClick={() => navigate("/login")}
        ></div>
        {
          //
          /**
           *
           * * show pages -> put keys of {pages} const
           * @IniciarSesionWith
           * @IniciarSesionData
           * @RestablecerPassword
           * @ConfirmarCorreoRecuperar
           * ? RegistroExitoso
           * ? CambiarPassword
           * @CrearCuenta
           * @ConfirmarRegistro
           *
           */
        }
        {Box(page)}
      </div>
    </main>
  );
}
const Component = ({ page }) => {
  const location = useLocation().pathname;
  return ReturnAuth(page);
};

export default Component;
