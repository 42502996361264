import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MigajasCategorias from "../../components/MigajasCategorias";
import axios from '../../fetch/axios';

const ListaSubcategorias = () => {
  const navigate = useNavigate();
  const { categoria } = useParams();
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await axios.get(`/categories/detail?uri=${categoria}`);
        setCategory(response.data);
      } catch (error) {
        console.error('Error fetching category details', error);
      } finally {
        setLoading(false);
      }
    };
    fetchCategory();
  }, [categoria]);

  const LoadingCard = () => {
    return (
      <>
          <div className="col-md-3">
            <div className="wrapper">
              <div className="card-loader card-loader--tabs"></div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="wrapper">
              <div className="card-loader card-loader--tabs"></div>
            </div>
          </div>
      </>
    );
  };

  const CategoryItem = (item, image) => {
    return (
      <article
        className="ImageButtonBG"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div>
          <div
            className="ButtonContent ButtonDarkMini"
            onClick={() => navigate(`/category/${categoria}/${item.uri}`)}
          >
            {item.name}
          </div>
        </div>
      </article>
    );
  };

  return (
    <>
      <MigajasCategorias nivel1={category ? category.name : "Loading..."} />
      <section className="CategoryBox">
        {loading ? LoadingCard() : category.subcategories.map((subcategory) => (
          CategoryItem(subcategory, subcategory.desktop_image)
        ))}
      </section>
    </>
  );
};

export default ListaSubcategorias;
